"use strict";

// The reason this file exists and is loaded in head is because of page scripts that might be run BEFORE the app bundle.
// Those script tags should ideally be removed completely.
// TODO: Look for script tags in cshtml and remove!

import $ from 'jquery';
import 'jquery-migrate';

$.migrateTrace = false;

window.jQuery = window.$ = $;

import "./diag";
