import * as Sentry from '@sentry/browser';

/** @type Api.SentryData */
const scope = JSON.parse(atob(document.head.dataset.env || '') || null);

Sentry.init({
	dsn: process.env.SENTRY_DSN,
	release: scope?.Release,
	environment: scope?.Environment,
	initialScope: {
		user: scope?.User,
		tags: scope?.Tags,
	},
});